import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, } from '@mui/material';
// components
import { useSnackbar } from 'notistack';
import Iconify from '../../../../components/iconify';
import { Upload } from '../../../../components/upload';
import { insertFiles } from '../../../../redux/slices/prothese/protheseSlice';
import { dispatch } from '../../../../redux/store';
// ----------------------------------------------------------------------
const ALLOWED_FILE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'tiff', 'stl'];
export default function UploadMultiple({ title = 'Télécharger des fichiers', open, onClose, 
//
onCreate, onUpdate, folderName, onChangeFolderName, currentFiles, ...other }) {
    const [files, setFiles] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (!open) {
            setFiles([]);
        }
    }, [open]);
    const handleDrop = useCallback((acceptedFiles) => {
        const filteredFiles = acceptedFiles.filter((file) => {
            const extension = file.name.split('.').pop()?.toLowerCase();
            return extension && ALLOWED_FILE_EXTENSIONS.includes(extension);
        });
        const newFiles = filteredFiles.map((file) => Object.assign(file, {
            preview: URL.createObjectURL(file),
        }));
        setFiles([...files, ...newFiles]);
    }, [files]);
    const handleUpload = () => {
        if (currentFiles?.length < 11 && currentFiles.length + files.length < 11) {
            dispatch(insertFiles([...currentFiles, ...files]));
            onClose();
        }
        else {
            enqueueSnackbar('Vous ne pouvez pas télécharger plus de 10 fichiers', {
                variant: 'error',
            });
            onClose();
        }
    };
    const handleRemoveFile = (inputFile) => {
        const filtered = files.filter((file) => file !== inputFile);
        setFiles(filtered);
    };
    const handleRemoveAllFiles = () => {
        setFiles([]);
    };
    return (_jsxs(Dialog, { fullWidth: true, maxWidth: "sm", open: open, onClose: onClose, ...other, children: [_jsxs(DialogTitle, { sx: { p: (theme) => theme.spacing(3, 3, 2, 3) }, children: [" ", title, " "] }), _jsxs(DialogContent, { dividers: true, sx: { pt: 1, pb: 0, border: 'none' }, children: [(onCreate || onUpdate) && (_jsx(TextField, { fullWidth: true, label: "Folder name", value: folderName, onChange: onChangeFolderName, sx: { mb: 3 } })), _jsx(Upload, { multiple: true, files: files, onDrop: handleDrop, onRemove: handleRemoveFile, description: "Seuls les images et les fichiers stl sont pris en charge" })] }), _jsxs(DialogActions, { children: [!!files.length && (_jsx(Button, { variant: "outlined", color: "inherit", onClick: handleRemoveAllFiles, children: "Enlever tout" })), _jsx(Button, { variant: "contained", startIcon: _jsx(Iconify, { icon: "eva:cloud-upload-fill" }), onClick: handleUpload, children: "Ajouter" }), (onCreate || onUpdate) && (_jsx(Stack, { direction: "row", justifyContent: "flex-end", flexGrow: 1, children: _jsx(Button, { variant: "soft", onClick: onCreate || onUpdate, children: onUpdate ? 'Sauvegarder' : 'Créer' }) }))] })] }));
}
